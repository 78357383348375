import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import { Facebook, Instagram, VK, Phone, MenyBurger, MenyClose, Telegram } from "./icons";

function Navbar() {
  const [activePage, setActivePage] = useState("");
  const [mobileMenu, setMobileMenu] = useState(false);

  useEffect(() => {
    setActivePage(window.whlotos.active_page);
  }, []);

  const mobilMenuClick = () => {
    setMobileMenu(!mobileMenu);
  };

  return (
    <React.Fragment>
      <nav className="tw-navbar">
        <div className="tw-container">
          <div className="tw-navbar-brand">
            <a className="tw-navbar-item" href="/">
              <img
                className="tw-navbar-logo"
                src="/static/logo.png"
                alt="Logo"
              />
            </a>
            <div className="tw-navbar-mobile" onClick={mobilMenuClick}>
              {!mobileMenu && <MenyBurger />}
              {mobileMenu && <MenyClose />}
            </div>
          </div>
          <div className={`tw-navbar-meny ${mobileMenu ? "is-active" : ""}`}>
            <a
              className={`tw-navbar-item ${
                activePage == "index" ? "is-active" : ""
              } `}
              href="/"
            >
              Главная
            </a>
            <a
              className={`tw-navbar-item ${
                activePage == "cards" ? "is-active" : ""
              } `}
              href="/cards"
            >
              Клубные карты
            </a>
            <a
              className={`tw-navbar-item ${
                activePage == "schedule" ? "is-active" : ""
              } `}
              href="/schedule"
            >
              Расписание
            </a>
            <a
              className={`tw-navbar-item ${
                activePage == "contacts" ? "is-active" : ""
              } `}
              href="/contacts">
              Контакты
            </a>

            <a
              className="tw-navbar-item"
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://vk.com/club54792350"
            >
              <VK />
            </a>

            <a
              className="tw-navbar-item"
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://t.me/whlotos_fitness"
            >
              <Telegram />
            </a>

            <a
              className="tw-navbar-item"
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://www.instagram.com/whlotos_fitness/"
            >
              <Instagram />
            </a>

            <a className="flex items-center tw-navbar-item" href="tel:323215">
              <span className="mx-1"><Phone /></span>
              <span>32-32-15</span>
            </a>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}

const e = React.createElement;

ReactDOM.render(e(Navbar), document.getElementById("react-navbar"));
